import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
// 

import pic08 from '../assets/images/pic08.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'


const Project = ({data}) => {
    let project = data.airtable.data;
    console.log(project.Title);
    console.log(project.Stack);
    
    return(
    <Layout>
        <Helmet>
            <title>{project.Title} by Mary Ojo</title>
            <meta name={project.Title} content="" />
        </Helmet>

        <div id="main" className='alt'>
        <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>{project.Title}</h1>
                    </header>

                    <div className="grid-wrapper">
                        <div className="col-6">
                            {/* <h3>Sem turpis amet semper</h3> */}
                            <p className='intro'>{project.Introduction}</p>
                            <p className=''><span className='intro'>Stack: </span>{project.Stack}</p>
                        <ul className='actions'>
                        <li><a href={project.GithubURL} className="button icon fa-link" target="_blank">Repository</a></li>
                        <li><a href={project.LiveURL} className="button icon fa-link" target="_blank">Live Web App</a></li>
                        </ul>
                        </div>
                        <div className="col-6">
                        <span className="image fit introImg"><img src={project.IntroPic} alt="" /></span>
                        </div>
                    </div>
                    <hr className="major" />
                    <span className="image fit"><img src={project.SecondPic} alt="" /></span>
                    <hr className="major" />
                     
                    <div className="grid-wrapper">
                        <div className="col-9">
                            <h3>Project Purpose and Goals</h3>
                            <p className='intro'>{project.Purpose}</p>
                            {/* <p className=''><span className='intro'>Stack: </span>{project.StackIntro}</p> */}
                        </div>
                        
                    </div>
                    <hr className="major" />
                    <h3>Stack Usage</h3>
                    <div className="grid-wrapper">
                            <div className='col-5'>
                            <div className="icon-grid" style={{gap : "30px"}}>
                                        <div className="col-2"><span className="image fit"><img src={project.StackLogo1} alt="" /></span></div>
                                        <div className="col-2"><span className="image fit"><img src={project.StackLogo2} alt="" /></span></div>
                                        <div className="col-2"><span className="image fit"><img src={project.StackLogo3} alt="" /></span></div>
                                        <div className="col-2"><span className="image fit"><img src={project.StackLogo4} alt="" /></span></div>
                                        {/* <div className="col-2"><span className="image fit"><img src={pic08} alt="" /></span></div>
                                        <div className="col-2"><span className="image fit"><img src={pic08} alt="" /></span></div> */}

                                    </div>
                            </div>
                            <p className='intro col-6'>{project.StackUsage}</p>
                            {/* <div className='col-9'></div> */}
                        <div className="col-12">    
                        </div>
                    </div>
                    <hr className="major" />

                    <h3>Screenshots</h3>

                    <div className="grid-wrapper">
                       
                        <div className="col-12">
                        <div className="box alt">
                                    <div className="grid-wrapper" style={{gap : "30px"}}>
                                        <div className="col-6"><span className="image fit"><img src={project.Screenshot1} alt="" /></span></div>
                                        <div className="col-6"><span className="image fit"><img src={project.Screenshot2} alt="" /></span></div>
                                        {/* <div className="col-6"><span className="image fit"><img src={pic10} alt="" /></span></div>
                                        <div className="col-6"><span className="image fit"><img src={pic10} alt="" /></span></div> */}
                                    </div>
                                
                        </div>
                        </div>
                    </div>
                    <hr className="major" />
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <h3>Testing</h3>
                            <p className='intro'>
                            {project.TestingIntro}</p>
                            {/* <p>
                            <b>Accessibility: </b>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non <br/><b>Responsiveness: </b>faucibus ornare mi ut ante amet placerat aliquet.<br/>
                            <b>Performance: </b>Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in<br/> 
                            <b>Usability: </b>Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in 
                            </p> */}
                        </div>
                        <div className="col-6">
                        {/* <span className="image fit"><img src={project.TestingImage} alt="" /></span> */}
                         <h3>Problems faced & Thought Process</h3>
                         <p className='intro'>{project.ThoughtProcess}</p>
                        </div>
                    </div>

                    {/* <hr className="major" /> */}

                    {/* <h3>Problems faced & Thought Process</h3>

                    <div className="grid-wrapper">
                       
                        <div className="col-12">
                        <p className='intro'>{project.ThoughtProcess}</p>
                        </div>
                    </div> */}
                    {/* <hr className="major" />
                    <span className="image fit"><img src={project.ThirdPic} alt="" /></span> */}
                    <hr className="major" />

                    <h3>Key Takeaways</h3>

                    <div className="grid-wrapper">
                       
                        <div className="col-12">
                        <p className='intro'>{project.Takeaways}</p>
                        <h4>Future improvements</h4>
                        <ul>
                        <li>{project.FutureImprovement1}</li>
                        <li>{project.FutureImprovement2}</li>
                        <li>{project.FutureImprovement3}</li>
                        </ul>
                        </div>
                    </div>

                    <hr className="major" />

                    <h3>Other Projects</h3>

                    {/* <div className="grid-wrapper"> */}
                       
                        {/* <div className="col-4"> */}
                                        {/* <div className="col-4"><span className="image fit"><img src={pic08} alt="" /></span></div>
                                        <div className="col-4"><span className="image fit"><img src={pic09} alt="" /></span></div>
                                        <div className="col-4"><span className="image fit"><img src={pic09} alt="" /></span></div>
                                        </div> */}
                    {/* </div> */}
                    <ul className='actions' style={{ display:'flex', justifyContent:'space-between'}}>
                        <li><Link to={`/${project.OP1Permalink}`} className="button icon fa-arrow-left">{project.OP1Title}</Link></li>
                        <li><Link to={`/${project.OP2Permalink}`} className="button">{`${project.OP2Title} `}<span className='icon fa-arrow-right'></span></Link></li>
                        </ul>
                </div>
                
            </section>
        </div>
    </Layout>
)
    }

export const query = graphql`
query Project2 {
    airtable(table: {eq: "tbliU3QHg1Vd23L3M"}, data: {ID: {eq: 2}}) {
      data {
        ID
        FutureImprovement1
        FutureImprovement2
        FutureImprovement3
        OP2Permalink
        OP2Title
        OP1Permalink
        OP1Title
        Takeaways
        ThirdPic
        ThoughtProcess
        TestingIntro
        TestingImage
        Screenshot4
        Screenshot3
        Screenshot2
        Screenshot1
        StackUsage
        StackLogo4
        StackLogo3
        StackLogo2
        StackLogo1
        Stack
        StackNumber
        Purpose
        SecondPic
        Accessibility
        GithubURL
        IntroPic
        Introduction
        LiveURL
        Permalink
        Title
      }
    }
  }
  `

export default Project